import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import Loader from './Components/Loading/Loading';

// Usando React.lazy para carregar os componentes dinamicamente
const Home = lazy(() => import('./Pages/Home'));
const Sobre = lazy(() => import('./Pages/Sobre/Sobre'));
const SejaFranqueado = lazy(() => import('./Pages/SejaFranqueado/SejaFranqueado'));
const FaleConosco = lazy(() => import('./Pages/FaleConosco'));
const Cardapio = lazy(() => import('./Pages/Cardapio'));
const Combos = lazy(() => import('./Pages/Combos'));
const Cupons = lazy(() => import('./Pages/Cupons'));
const NossaHistoria = lazy(() => import('./Pages/NossaHistoria'));
const Ofertas = lazy(() => import('./Pages/Ofertas'));
const Franqueadora = lazy(() => import('./Pages/Franqueadora'));
const TrabalheConosco = lazy(() => import('./Pages/TrabalheConosco'));
const TermosDeUso = lazy(() => import('./Pages/TermosDeUso'));
const TermosCupons = lazy(() => import('./Pages/TermosCupons'));
const PoliticaPrivacidade = lazy(() => import('./Pages/PoliticaPrivacidade'));
const BaixeApp = lazy(() => import('./Pages/BaixeApp'));
const RaspadinhaDigital = lazy(() => import('./Components/Raspadinha/Raspadinha'));
const AdsTest = lazy(() => import('./Pages/AdsTest'));
const DesignSystem = lazy(() => import('./Pages/DesignSystem'));
const Lojas = lazy(() => import('./Pages/Lojas/Lojas'));
const Loja = lazy(() => import('./Pages/Lojas/Loja'));
const TermsConditionsRaspadinha = lazy(() => import('./Components/Termos/TermsConditionsRaspadinha'));
const PageNotFound = lazy(() => import('./Pages/PageNotFound'));
const RedirectToApp = lazy(() => import('./Pages/RedirectToApp'));

// Top Posts SEO
const PostPizzaCalabresaPepperoni = lazy(() => import('./Components/TopPosts/PostPizzaCalabresaPepperoni'));
const PostOrigemPizzaCaprese = lazy(() => import('./Components/TopPosts/PostOrigemPizzaCaprese'));
const PostBeneficiosPresuntoParma = lazy(() => import('./Components/TopPosts/PostBeneficiosPresuntoParma'));
const PostPizzaPertoDeMim = lazy(() => import('./Components/TopPosts/PostPizzaPertoDeMim'));

const AppRoutes = () => {

  const ExternalRedirect = () => {
    const location = useLocation();
  
    useEffect(() => {
      if (location.pathname === '/blog') {
        window.location.href = 'https://blog.pizzaprime.com.br';
      }
    }, [location.pathname]);
  
    return null;
  };

  return (
    <Router>
      <ScrollToTop />
      {/* Envolve todas as rotas com Suspense e o Loader */}
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* Rota para redirecionar todas as URLs que contêm /unidades para /lojas */}
          <Route path="/unidades/*" element={<Navigate to="/lojas" replace />} />
          {/* Fim. Rota para redirecionar todas as URLs que contêm /unidades para /lojas */}
          
          {/* Redirecionamento de unidades antigo */}
          <Route path="/unidades/rio-grande-do-sul/novo-hamburgo" element={<Navigate to="/novo-hamburgo" replace />} />
          <Route path="/unidades/rio-grande-do-sul/pelotas" element={<Navigate to="/pelotas" replace />} />
          <Route path="/unidades/rio-grande-do-sul/rio-grande" element={<Navigate to="/rio-grande" replace />} />

          <Route path="/unidades/sao-paulo/vila-matilde" element={<Navigate to="/vila-matilde" replace />} />
          <Route path="/unidades/sao-paulo/campo-belo" element={<Navigate to="/campo-belo" replace />} />
          <Route path="/unidades/sao-paulo/osasco" element={<Navigate to="/osasco" replace />} />
          <Route path="/unidades/sao-paulo/tatui" element={<Navigate to="/tatui" replace />} />
          <Route path="/unidades/sao-paulo/salto" element={<Navigate to="/salto" replace />} />
          <Route path="/unidades/sao-paulo/bela-vista-paulista" element={<Navigate to="/bela-vista" replace />} />
          <Route path="/unidades/sao-paulo/aclimacao-ipiranga" element={<Navigate to="/aclimacao" replace />} />
          <Route path="/unidades/sao-paulo/campinas-cambui" element={<Navigate to="/campinas" replace />} />
          <Route path="/unidades/sao-paulo/ribeirao-preto" element={<Navigate to="/ribeirao-preto" replace />} />
          <Route path="/unidades/sao-paulo/mogi-das-cruzes" element={<Navigate to="/mogi-das-cruzes" replace />} />          
          <Route path="/unidades/sao-paulo/valinhos" element={<Navigate to="/valinhos" replace />} />        
          <Route path="/unidades/sao-paulo/valinhos" element={<Navigate to="/valinhos" replace />} />
          
          <Route path="/unidades/santa-catarina/itapema" element={<Navigate to="/itapema" replace />} />
          <Route path="/unidades/santa-catarina/chapeco" element={<Navigate to="/chapeco" replace />} />
          <Route path="/unidades/santa-catarina/itajai" element={<Navigate to="/chapeco" replace />} />
          <Route path="/unidades/santa-catarina/criciuma" element={<Navigate to="/criciuma" replace />} />
          <Route path="/unidades/santa-catarina/balneario-camboriu" element={<Navigate to="/balneario-camboriu" replace />} />

          {/* Fim. Redirecionamento de unidades antigo */}

          {/* Rota para redirecionar as melhores urls do blog */}
            <Route path="/cardapio-pizza-prime/*" element={<Navigate to="/cardapio" replace />} />
            <Route path="/tag/*" element={<Navigate to="/" replace />} />          
            <Route path="/categoria/*" element={<Navigate to="/" replace />} />          
            <Route path="/onde-comer-pizza/*" element={<Navigate to="/" replace />} />    
            <Route path="/pizza-em-casa/*" element={<Navigate to="/" replace />} />     
            <Route path="/aplicativo-pizza-prime/*" element={<Navigate to="/" replace />} />   
            <Route path="/pedido_de_pizza_online/*" element={<Navigate to="/" replace />} />     
            <Route path="/blog/*" element={<ExternalRedirect />} />
          {/* Rota para redirecionar as melhores urls do blog */}         

          {/* Outras rotas */}
          <Route path="/" element={<Home />} />
          <Route path="/sobre" element={<Sobre />} />
          <Route path="/franquia-de-pizzaria" element={<SejaFranqueado />} />
          <Route path="/fale-conosco" element={<FaleConosco />} />
          <Route path="/cardapio" element={<Cardapio />} />
          <Route path="/combos" element={<Combos />} />
          <Route path="/cupons" element={<Cupons />} />
          <Route path="/nossa-historia" element={<NossaHistoria />} />
          <Route path="/ofertas" element={<Ofertas />} />
          <Route path="/franqueadora" element={<Franqueadora />} />
          <Route path="/trabalhe-conosco" element={<TrabalheConosco />} />
          <Route path="/termos-de-uso" element={<TermosDeUso />} />
          <Route path="/termos-cupons" element={<TermosCupons />} />
          <Route path="/politica-privacidade" element={<PoliticaPrivacidade />} />
          <Route path="/app/download" element={<BaixeApp />} />
          <Route path="/raspadinha-digital" element={<RaspadinhaDigital />} />
          <Route path="/ads" element={<AdsTest />} />
          <Route path="/lojas" element={<Lojas />} />
          <Route path="/:parametro" element={<Loja />} />
          <Route path="/DesignSystem" element={<DesignSystem />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/promocao/raspadinha/termos" element={<TermsConditionsRaspadinha />} />
          <Route path="redirectToApp" element={<RedirectToApp />} />          
          
          <Route path='/pizza-de-calabresa-e-pizza-de-pepperoni' element={<PostPizzaCalabresaPepperoni />} />
          <Route path='/origem-pizza-caprese' element={<PostOrigemPizzaCaprese />} />
          <Route path='/beneficios-do-presunto-parma' element={<PostBeneficiosPresuntoParma />} />
          <Route path='/pizza-perto-de-mim' element={<PostPizzaPertoDeMim />} />

          <Route path="/perguntas-frequentes" element={<Home />} />
          <Route path="/termos-e-condicoes" element={<Home />} />
          <Route path="/pizza-prime-delivery" element={<Home />} />
          <Route path="/terca-em-dobro" element={<Home />} />
          <Route path="/clipping" element={<Home />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default AppRoutes;
